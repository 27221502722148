/**
 * Direttiva che costruisce la lista con i corsi
 */

export interface ICourseListDirectiveScope extends ng.IScope {
    goToCourseDetail: Function;
	chartOptions: any;
	card: any;
	windowWidth: number;
	isWidthExceeded: boolean;
	globalApplicationData: any;
	item: any;
	isSoftSkill: boolean;
	isTechSkill: boolean;
	creditType: string;
	creditValue: number;
	techCompetences: any;
	softCompetences: any;
	forceToggleIndicator: boolean;
	defaultOnSuggestion: boolean;
	bgacademyApplicationData: any;
	enableOrangeColor: Function;
	enableRedColor: Function;
	enableGreyColor: Function;
	isCourse: boolean;
	isCourseOnline: boolean;
	isUserStatusPresent: Function;
	isSurveyReady: Function;
	isThereValidSurveyTemplate: Function;
	enableSoftGreyColor: Function;
	isUserStatusInvited: Function;
	isUserStatusConfirmed: Function;
	isUserStatusRefused: Function;
	isStopDateExceeded: Function;
	isUserPresentAndSurveyPassedOrAbsentAndCertificateReady: Function;
}
angular.module('app').directive("courseList", ($window, $state, BgacademyApplicationData, GlobalApplicationData) => {
	return {
		restrict: 	'E',
		transclude : true,
		scope: {
			item: '=', // Oggetto interno contenente tutte le informazioni della riga che sto andando a costruire
            selectedItemList: "=", // bind sull'array che conterrà le righe selezionate
			forceToggleIndicator: '=', // Toggle indicatore di default (open, consumed, percentage...) / suggeritore
			page: '@',
			isLearningPlanEditingMode: '@',
			openItemDetail: '&',
			suggestedPersonExists: '&',
			toggleSuggestedPerson: '&',
			itemChildsExists: '&',
			toggleItemChilds: '&'
		},
		link: link,
		templateUrl: 'app/shared/courseList/courseList.html'
	};
	function link($scope: ICourseListDirectiveScope, element: JQuery, attrs: ng.IAttributes){
		// Collegamento all'oggetto principale
		$scope.bgacademyApplicationData = BgacademyApplicationData;
		// Item passato alla Direttiva. Se esiste il referenceId significa che si tratta 
		// di un childItem
		$scope.card = $scope.item;

		// Verifica se come prima cosa devo mostrare la persona (non ho altri dati disponibili)
		$scope.defaultOnSuggestion = true;

		// Verifica se il corso è online
		$scope.isCourse = !!$scope.card.courseType;
		$scope.isCourseOnline = $scope.card.courseType == $scope.bgacademyApplicationData.constants.ONLINE ? true : false;

		// Configurazione della percentuale
		$scope.chartOptions = {
			animate: {
				duration: 800,
				enabled: true
			},
			barColor: '#1abc9c',
			scaleColor: '',
			trackColor: '#fff', 
			lineWidth: 2,
			lineCap: 'butt',
			size: 35
		}

		// Collegamento all'oggetto globale
		$scope.globalApplicationData = GlobalApplicationData;

		$scope.windowWidth = $window.innerWidth;
		// Cambio la classe del titolo in base alla sua lunghezza, così diminuendo il font-size evito che sfori
		if($scope.card && $scope.card.courseTitle.length < 62){
			$scope.card.titleClass = 'card-title-h3';
		} else if($scope.card && $scope.card.courseTitle.length > 62 && $scope.card.courseTitle.length > 62) {
			$scope.card.titleClass = 'card-title-h2';
		}

		// Verifica se l'utente è in stato presente
		$scope.isUserStatusPresent = () => {
			if($scope.card.userStatus && $scope.card.userStatus == $scope.bgacademyApplicationData.constants.COURSE_USER_STATUS_PRESENT){
				return true;
			}
			
			return false;
		}

		// Verifica se l'utente ha l'invito pendente
		$scope.isUserStatusInvited = () => {
			if($scope.card.userStatus && $scope.card.userStatus == $scope.bgacademyApplicationData.constants.COURSE_USER_STATUS_INVITED){
				return true;
			}
			
			return false;
		}

		// Verifica se l'utente è confermato
		$scope.isUserStatusConfirmed = () => {
			if($scope.card.userStatus && $scope.card.userStatus == $scope.bgacademyApplicationData.constants.COURSE_USER_STATUS_CONFIRMED){
				return true;
			}
			
			return false;
		}

		// Verifica se l'utente è rifiutato
		$scope.isUserStatusRefused = () => {
			if($scope.card.userStatus && $scope.card.userStatus == $scope.bgacademyApplicationData.constants.COURSE_USER_STATUS_CANCELLED){
				return true;
			}
			
			return false;
		}

		// Verifica se c'è un template valido per la survey
		$scope.isThereValidSurveyTemplate = () => {
			if($scope.card.templateStatus && $scope.card.templateStatus == $scope.bgacademyApplicationData.constants.VALID){
				return true;
			}

			return false;
		}

		// Verifica se la survey è iniziata, o è pronta per essere iniziata o non è stata passata)
		$scope.isSurveyReady = () => {
			if($scope.isThereValidSurveyTemplate() && ((!$scope.card.surveyStatus) || ($scope.card.surveyStatus == $scope.bgacademyApplicationData.constants.STARTED || $scope.card.surveyStatus == $scope.bgacademyApplicationData.constants.NOT_PASSED))){
				return true;
			}

			return false;
		}

		// Verifica se abilitare il colore grigio, cioè qualora ci sia una verifica completata o non ci sia affatto (quindi neppure un template survey) oppure il corso sia scaduto e l'utente si trovi negli stati INVITATO, CONFERMANTO o RIFIUTATO
		$scope.isUserPresentAndSurveyPassedOrAbsentAndCertificateReady = () => {
			if($scope.card.objDisabled == true || ($scope.isUserStatusPresent() && (($scope.card.surveyStatus && $scope.card.surveyStatus == $scope.bgacademyApplicationData.constants.PASSED) || !$scope.isThereValidSurveyTemplate()))){
				return true;
			}

			return false;
		}

		//Verifica se l'edizione è scaduta
		$scope.isStopDateExceeded = () => {
			let now = new Date();
            if (now > $scope.card.stopDate) {
                return true;
            }

			return false;
		}
 
		// Verifica se abilitare il colore arancione, cioè nel caso di corso in aula e lo stato dell'utente non è presente
		$scope.enableOrangeColor = () => {
			if(($scope.card.cardType == "COURSE" || ($scope.isCourse && !$scope.isCourseOnline)) && (!$scope.isUserStatusPresent() || ($scope.isUserStatusPresent() && $scope.isSurveyReady()))){
				return true;
			}

			return false;
		}

		// Verifica se abilitare il colore rosso, cioè nel caso di corso online e lo stato dell'utente non è presente
		$scope.enableRedColor = () => {
			if(($scope.card.cardType == "ONLINE" || ($scope.isCourse && $scope.isCourseOnline))  && (!$scope.isUserStatusPresent() || ($scope.isUserStatusPresent() && $scope.isSurveyReady()))){
				return true;
			}

			return false;
		}

		// Verifica se abilitare il colore grigio, cioè qualora ci sia una verifica completata o non ci sia affatto (quindi neppure un template survey)
		$scope.enableGreyColor = () => {
			if(($scope.isCourse || $scope.card.cardType == "COURSE" || $scope.card.cardType == "ONLINE" ) && $scope.card.objDisabled == true || (($scope.isUserStatusInvited() || $scope.isUserStatusConfirmed() || $scope.isUserStatusRefused()) && $scope.isStopDateExceeded()) || ($scope.isUserStatusPresent() && (($scope.card.surveyStatus && $scope.card.surveyStatus == $scope.bgacademyApplicationData.constants.PASSED) || !$scope.isThereValidSurveyTemplate()))){
				return true;
			}

			return false;
		}

		// Verifica se abilitare il colore grigio chiaro, cioè quando è una newsDetail
		$scope.enableSoftGreyColor = () => {
			if($scope.card.cardType == "NEWS" || $scope.card.cardType == "NOTE" || $scope.card.cardType == "QUESTION"){
				return true;
			}

			return false;
		}

		// Eseguo la verifica sul carattere anche al resize e al caricamento della pagina
		angular.element($window).bind('resize', () => {
			// Calcolo la larghezza della finestra
			$scope.windowWidth = $window.innerWidth;

			if($scope.windowWidth < 600 && $scope.card && $scope.card.courseTitle.length < 62){
				$scope.card.titleClass = 'card-title-h6';
			} else if($scope.isWidthExceeded == true){
				$scope.card.titleClass = 'card-title-h3';
			}

			if($scope.windowWidth < 600 && $scope.card.courseTitle.length > 62 && $scope.card.courseTitle.length > 62){
				$scope.card.titleClass = 'card-title-h5';
			} else if($scope.isWidthExceeded == true){
				$scope.card.titleClass = 'card-title-h2';
			}
		});

		// e devo controllare al caricamento della pagina
		angular.element(document).ready(() => {
			// Calcolo la larghezza della finestra
			$scope.windowWidth = $window.innerWidth;

			if($scope.windowWidth < 600 && $scope.card && $scope.card.courseTitle.length < 62){
				$scope.card.titleClass = 'card-title-h6';
			} else if($scope.isWidthExceeded == true){
				$scope.card.titleClass = 'card-title-h3';
			}

			if($scope.windowWidth < 600 && $scope.card.courseTitle.length > 62 && $scope.card.courseTitle.length > 62){
				$scope.card.titleClass = 'card-title-h5';
			} else if($scope.isWidthExceeded == true){
				$scope.card.titleClass = 'card-title-h2';
			}
		});

		// Verifico gli attributi dell'oggetto
		if ($scope.card) {

			// Verifico il tipo di oggetto
			if ($scope.card.courseType == BgacademyApplicationData.constants.COURSE) {
				$scope.card.courseType = 'IN AULA';
			} else if ($scope.card.courseType == BgacademyApplicationData.constants.ONLINE) {
				$scope.card.courseType = 'ONLINE';
			}

			if ($scope.card.courseAttributes) {
				let courseAttributes = $scope.card.courseAttributes;
				for (let k = 0; k < courseAttributes.length; k++) {
					// Verifico se è una competenza soft
					if (courseAttributes[k].attributeType == BgacademyApplicationData.constants.SOFT_SKILL) {
						$scope.isSoftSkill = true;
					}
					// Verifico se è una competenza tecnica
					if (courseAttributes[k].attributeType == BgacademyApplicationData.constants.TECHNICAL_SKILL) {
						$scope.isTechSkill = true;
					}

					if (courseAttributes[k].courseAttributeType) {
						if (courseAttributes[k].courseAttributeType == BgacademyApplicationData.constants.IVASS_TYPE) {
							$scope.creditType = BgacademyApplicationData.constants.IVASS;
							$scope.creditValue = courseAttributes[k].courseAttributeValue;
						} else if (courseAttributes[k].courseAttributeType == BgacademyApplicationData.constants.EFA_A_TYPE) {
							$scope.creditType = BgacademyApplicationData.constants.EFA_A;
							$scope.creditValue = courseAttributes[k].courseAttributeValue;
						} else if (courseAttributes[k].courseAttributeType == BgacademyApplicationData.constants.EFA_B_TYPE) {
							$scope.creditType = BgacademyApplicationData.constants.EFA_B;
							$scope.creditValue = courseAttributes[k].courseAttributeValue;
						} else if (courseAttributes[k].courseAttributeType == BgacademyApplicationData.constants.EFP_TYPE) {
							$scope.creditType = BgacademyApplicationData.constants.EFP;
							$scope.creditValue = courseAttributes[k].courseAttributeValue;
						} else if (courseAttributes[k].courseAttributeType == BgacademyApplicationData.constants.EXTRA_TYPE) {
							$scope.creditType = BgacademyApplicationData.constants.EXTRA;
							$scope.creditValue = courseAttributes[k].courseAttributeValue;
						}
					}

					// Recupero i tag - se non c'è il crossReferenceObject significa che non è un vero tag, quindi lo salto
					if (courseAttributes[k].crossReferenceObject) {
						if (courseAttributes[k].attributeType == BgacademyApplicationData.constants.TECH_COMPETENCES) {
							$scope.techCompetences.push(courseAttributes[k].crossReferenceObject.title);
						} else if (courseAttributes[k].attributeType == BgacademyApplicationData.constants.SOFT_COMPETENCES) {
							$scope.softCompetences.push(courseAttributes[k].crossReferenceObject.title);
						}
					}
				}
			}

			// Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente
			/*for(let d = 0; d < $scope.item.engagements.length; d++){
				if($scope.item.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_OPEN){
					// Oggetto aperto
					$scope.isItemOpen = true;
				}
				
				if($scope.item.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_STARTED){
					// Recupero la percentuale
					for(let z = 0; z < $scope.item.engagements[d].engagementDetails.length; z++){
						if($scope.item.engagements[d].engagementDetails[z].detailKey == 'PERCENTAGE'){
							$scope.percentageItem = $scope.item.engagements[d].engagementDetails[z].detailValue;
							break;
						}
					}
				}
				
				if($scope.item.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_CONSUMED){
					// Recupero la percentuale
					for(let z = 0; z < $scope.item.engagements[d].engagementDetails.length; z++){
						if($scope.item.engagements[d].engagementDetails[z].detailKey == 'PERCENTAGE'){
							$scope.percentageItem = $scope.item.engagements[d].engagementDetails[z].detailValue;
							break;
						}
					}
				}
			}*/

			/* Se l'oggetto non è aperto, nè iniziato nè completato allora cerco i suggeriti
			if(!$scope.isItemOpen && !$scope.percentageItem){
				// Verifico se ci sono suggeriti (visibility = 'suggestion'), e se ci sono li aggiungo alla lista
				for(let j = 0; j < $scope.item.itemVisibilities.length; j++){
					// Se è stato suggerito dall'amministrazione, ha la precedenza
					if($scope.item.itemVisibilities[j].visibilityType == BgacademyApplicationData.constants.SUGGESTION && $scope.item.itemVisibilities[j].fromAdmin == true){
						$scope.suggestedFromAdmin = true;
					} else if($scope.item.itemVisibilities[j].visibilityType == BgacademyApplicationData.constants.SUGGESTION){
						// Verifico se ho i permessi per visualizzarlo
						let groupFound: boolean = false;
						for(let z = 0; z < $scope.globalApplicationData.userGroups.length; z++) {
							if ($scope.globalApplicationData.userGroups[z].groupId == $scope.item.itemVisibilities[j].allowedGroupId) {
								groupFound = true;
								break;
							}
						}
						if(groupFound || ($scope.globalApplicationData.jwtPayload.user.userId == $scope.item.itemVisibilities[j].allowedUserId)){
							$scope.hasSuggested = true;

							// Salvo chi me l'ha suggerito
							$scope.suggestedPerson = $scope.item.itemVisibilities[j].userObject;
						}
					}
				}
			}*/

		}

		// Porta alla pagina di dettaglio del corso selezionato
		$scope.goToCourseDetail = (courseId: string, courseDateId: string) => {
			if ($scope.card.cardType == "NEWS"){
				$state.go("app.bgacademyApp.newsDetail", { newsId: $scope.card.announcementId });
			}
			else if(courseDateId){
				$state.go("app.bgacademyApp.courseDetail", { courseId: courseId, courseDateId: courseDateId });
			}
			return;
        }
	}
});